import { colors, Grid, VStack } from "@biblioteksentralen/js-utils";
import { Heading } from "@chakra-ui/layout";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Text } from "@chakra-ui/react";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { Edit } from "../../editInSanity/EditInSanity";
import { HeaderFooterData, ResolvedFooterMenu } from "../sanityQuery";
import { useCommonData } from "../CommonDataProvider";
import { ContactFooterMenuItems } from "./ContactFooterMenuItems";
import { FooterMenuItems, menuItemHasContent } from "./FooterMenuItems";
import { SiteFooterMenuItems } from "./SiteFooterMenuItems";

const footerMenuHasContent = ({ menuItems = [] }: ResolvedFooterMenu) => !!menuItems.filter(menuItemHasContent).length;

const MAX_CUSTOM_FOOTER_MENUS = 2;

export const FooterMenu = () => {
  const { headerFooterData } = useCommonData();
  if (!headerFooterData) return null;

  const showContactMenu = !!headerFooterData.libraries[0] || headerFooterData.employeeCount > 0;

  return (
    <Box as="section" color={colors.grey10} borderBottom={`${colors.grey45} solid 0.1rem`} paddingBottom="3rem">
      <Box display={{ lg: "none" }}>
        <FooterMenuMobile headerFooterData={headerFooterData} showContactMenu={showContactMenu} />
      </Box>
      <Box display={{ base: "none", lg: "initial" }}>
        <FooterMenuDesktop headerFooterData={headerFooterData} showContactMenu={showContactMenu} />
      </Box>
      {headerFooterData.footerMenuCollection && <Edit doc={headerFooterData.footerMenuCollection} />}
    </Box>
  );
};

const FooterMenuDesktop = ({
  headerFooterData,
  showContactMenu,
}: {
  headerFooterData: HeaderFooterData;
  showContactMenu: boolean;
}) => {
  const { t, ts } = useTranslation();

  return (
    <Grid gridTemplateColumns="repeat(auto-fill, minmax(15rem, 1fr))" whiteSpace="nowrap" gap="2rem">
      {showContactMenu && (
        <VStack alignItems="start" marginInlineStart="0!important">
          <Heading as="h2" marginBottom=".5rem" size="1rem">
            {t("Kontakt")}
          </Heading>
          <ContactFooterMenuItems headerFooterData={headerFooterData} />
        </VStack>
      )}
      {headerFooterData.footerMenuCollection?.footerMenus
        ?.filter(footerMenuHasContent)
        .slice(0, MAX_CUSTOM_FOOTER_MENUS)
        .map((footerMenu, i) => (
          <VStack alignItems="start" key={footerMenu._key ?? i} marginInlineStart="0!important">
            <Heading as="h2" marginBottom=".5rem" size="1rem">
              {ts(footerMenu?.menuHeader)}
            </Heading>
            <FooterMenuItems footerMenu={footerMenu} />
          </VStack>
        ))}
      <VStack alignItems="start" marginInlineStart="0!important">
        <Heading as="h2" marginBottom=".5rem" size="1rem">
          {t("Nettstedet")}
        </Heading>
        <SiteFooterMenuItems accesibilityStatementUrl={headerFooterData.accesibilityStatementUrl} />
      </VStack>
    </Grid>
  );
};

const FooterMenuMobile = ({
  headerFooterData,
  showContactMenu,
}: {
  headerFooterData: HeaderFooterData;
  showContactMenu: boolean;
}) => {
  const { t, ts } = useTranslation();
  return (
    <Accordion as="ul" listStyleType="none" width="100%" reduceMotion allowMultiple>
      {showContactMenu && (
        <AccordionItem as="li" border="none">
          <Heading as="h2" marginBottom=".5rem" size="1rem">
            <AccordionButton boxShadow="none !important" padding="0" justifyContent="space-between">
              <Text fontWeight={600}>{t("Kontakt")}</Text>
              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel padding="0 0 1rem">
            <ContactFooterMenuItems headerFooterData={headerFooterData} />
          </AccordionPanel>
        </AccordionItem>
      )}
      {headerFooterData.footerMenuCollection?.footerMenus
        ?.filter(footerMenuHasContent)
        .slice(0, MAX_CUSTOM_FOOTER_MENUS)
        .map((footerMenu, i) => (
          <AccordionItem as="li" border="none" key={footerMenu._key ?? i} id={footerMenu?._key}>
            <Heading as="h2" marginBottom=".5rem" size="1rem">
              <AccordionButton boxShadow="none !important" padding="0" justifyContent="space-between">
                <Text fontWeight={600}>{ts(footerMenu?.menuHeader)}</Text>
                <AccordionIcon />
              </AccordionButton>
            </Heading>
            <AccordionPanel padding="0 0 1rem">
              <FooterMenuItems footerMenu={footerMenu} />
            </AccordionPanel>
          </AccordionItem>
        ))}
      <AccordionItem as="li" border="none">
        <Heading as="h2" marginBottom=".5rem" size="1rem">
          <AccordionButton boxShadow="none !important" padding="0" justifyContent="space-between">
            <Text fontWeight={600}>{t("Nettstedet")}</Text>
            <AccordionIcon />
          </AccordionButton>
        </Heading>
        <AccordionPanel padding="0 0 1rem">
          <SiteFooterMenuItems accesibilityStatementUrl={headerFooterData.accesibilityStatementUrl} />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
