import { Box, Heading, Text } from "@chakra-ui/layout";
import { Link } from "@chakra-ui/react";
import { getSiteUrl, richTextHasContent } from "@libry-content/common";
import { PrivacyPolicy } from "@libry-content/types";
import format from "date-fns/format";
import max from "date-fns/max";

import { Edit } from "../../components/editInSanity/EditInSanity";
import { ResolvedSite } from "../../utils/fetchWithCommonDataAndCurrentSiteFilter";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { generatePrivacyPolicyUrl } from "../../utils/librarySystemPrivacyPolicy";
import BlockContent, { RichTextStyle } from "../blockContent/BlockContent";
import { DefaultContainer } from "../ContentContainer";

export const PRIVACY_POLICY_PATH = "/personvern";

const HARDCODED_TEXT_UPDATED = "2022-06-07T07:31:50.510Z";
const HARDCODED_TEXT_VERSION = "1";

export const getPrivacyPolicyPublishedDate = (
  site: ResolvedSite,
  privacyPolicy: Pick<PrivacyPolicy, "trackUpdatedFields">
): Date => {
  const candidateDates = [new Date(HARDCODED_TEXT_UPDATED), new Date(site._createdAt)];

  if (privacyPolicy?.trackUpdatedFields?.updated) {
    candidateDates.push(new Date(privacyPolicy?.trackUpdatedFields?.updated));
  }

  return max(candidateDates);
};

type Props = {
  site: ResolvedSite;
  privacyPolicy: PrivacyPolicy;
};

const PrivacyPolicy = ({ site, privacyPolicy }: Props) => {
  const { t, ts } = useTranslation();
  const email = privacyPolicy?.privacyPolicyContactEmail;
  const librarySystemPrivacyPolicyUrl = generatePrivacyPolicyUrl(site.librarySystem);
  const publishedDate = format(getPrivacyPolicyPublishedDate(site, privacyPolicy), "dd.MM.yyyy");
  const path = `${getSiteUrl(site)}${PRIVACY_POLICY_PATH}`;
  const privacyPolicyAddendum = ts(privacyPolicy?.privacyPolicyAddendum);

  return (
    <DefaultContainer padding="0 1rem 2rem">
      <RichTextStyle>
        {privacyPolicy && (
          <Edit
            doc={privacyPolicy}
            marginTop="1rem"
            textAlign="center"
            position="relative"
            left="50%"
            transform="translateX(-50%)"
          />
        )}
        <Heading as="h1" marginTop="2rem" marginBottom="1rem">
          {t("Personvernerklæring")}
        </Heading>
        <Text>
          {t("Nettsidene er utviklet av Biblioteksentralen i samarbeid med biblioteket.")}{" "}
          {t("Vi gjør vårt beste for å beskytte dine personopplysninger, også når de ikke er av sensitiv art.")}{" "}
          {t("Denne personvernerklæringen forteller om hvordan vi samler inn og bruker personopplysninger.")}
        </Text>
        <Heading as="h2">{t("Behandlingsansvarlig")}</Heading>
        <Text>
          {t(
            "Biblioteket, ved biblioteksjefen, er behandlingsansvarlig for Bibliotekets behandling av personopplysninger."
          )}
          {t(
            "Erklæringen inneholder opplysninger du har krav på når det samles inn opplysninger fra nettstedet vårt (personopplysningsloven § 19) og generell informasjon om hvordan vi behandler personopplysninger (personopplysningsloven § 18 1. ledd)."
          )}
        </Text>
        <Heading as="h2">{t("Versjoner")}</Heading>
        <Text>
          {t("Dette er versjon {versjon} av personvernerklæringen.", {
            versjon: `${HARDCODED_TEXT_VERSION}.${privacyPolicy?.trackUpdatedFields?.version ?? 0}`,
          })}{" "}
          {t("Den ble publisert på")} {<Link href={path}>{path.replace(/^https?:\/\//, "")}</Link>}{" "}
          {t("den {dato}.", { dato: publishedDate })}
        </Text>
        <Heading as="h2">{t("Informasjonskapsler (cookies) og webstatistikk")}</Heading>
        <Text>
          {t(
            "Informasjonskapsler (cookies) er små tekstfiler som plasseres på din datamaskin når du laster ned en nettside."
          )}
          {t("Biblioteksentralen bruker ikke cookies til å generere statistikk.")}{" "}
          {t("Vi kan dermed ikke spore din bruk av nettstedet tilbake til deg som enkeltperson.")}
        </Text>
        <Text>
          {t(
            "Nettsidene benytter Plausible som analyseverktøy for å samle inn opplysninger om besøkende på våre nettsider."
          )}
          {t("Plausible benytter ikke informasjonskapsler og all data om besøkende er anonymiserte.")}
        </Text>
        <Heading as="h2">{t("Sikkert nettsted")}</Heading>
        <Text>
          {t("Biblioteksentralen benytter HTTPS for å sikre våre nettløsninger mot hacking.")}{" "}
          {t(
            "HTTPS gjør at kommunikasjonen skjer sikkert mellom nettleseren i din datamaskin/mobile enhet og serveren som driver nettstedet."
          )}
        </Text>
        <Heading as="h2">{t("Mine sider / biblioteksystem")}</Heading>
        <Text>
          {/* TODO: Does this need updating? */}
          {t(
            "Ved søk i katalog eller innlogging Mine sider / Mappa mi blir du overført til bibliotekets kjernesystem."
          )}
          {librarySystemPrivacyPolicyUrl && (
            <>
              {" "}
              <Link href={librarySystemPrivacyPolicyUrl}>{t("Kjernesystemets personvernerklæring.")}</Link>
            </>
          )}
        </Text>
        <Heading as="h2">{t("Eksterne lenker")}</Heading>
        <Text>
          {t("Biblioteket er ikke ansvarlig for personvern på direktekoblinger utenfor bibliotekets nettsider.")}
        </Text>
        {richTextHasContent(privacyPolicyAddendum) && (
          <>
            <Heading as="h2">{t("Annen relevant informasjon")}</Heading>
            {/* In order to have the block content headings subordinat to the heading above, we transform h2 headings to h3 */}
            <BlockContent blocks={privacyPolicyAddendum} styleMap={{ h2: "h3" }} />
          </>
        )}
        <Heading as="h2">{t("Har du spørsmål til vår behandling av personopplysninger?")}</Heading>
        <Text>
          {t(
            "Alle som spør har rett på grunnleggende informasjon om behandlinger av personopplysninger i en virksomhet etter personopplysningslovens § 18, 1. ledd."
          )}{" "}
          {t(
            "Biblioteksentralen har gitt denne informasjonen i denne erklæringen, og vil henvise til den ved eventuelle forespørsler."
          )}
        </Text>
        <Text>
          {t("De som er registrert i en av bibliotekets systemer har rett på innsyn i egne opplysninger.")}{" "}
          {t(
            "Vedkommende har også rett til å be om at uriktige, ufullstendige eller opplysninger biblioteket ikke har adgang til å behandle blir rettet, slettet eller supplert."
          )}
        </Text>
        {email && (
          <>
            <Heading as="h2">{t("Kontaktinformasjon")}</Heading>
            <Box>
              <Text>{t("E-post: {epost}", { epost: email })}</Text>
            </Box>
          </>
        )}
        <Heading as="h2">{t("Endringer")}</Heading>
        <Text>
          {t(
            "Biblioteket kan revidere denne personvernerklæringen ved endringer i behandlingen av personopplysninger eller ved endringer i gjeldende lovgivning."
          )}{" "}
          {t("Når personvernerklæringen endres, vil den nye personvernerklæring publiseres på vår hjemmeside.")}
        </Text>
      </RichTextStyle>
    </DefaultContainer>
  );
};

export default PrivacyPolicy;
