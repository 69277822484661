/* eslint i18next/no-literal-string: 0 */
import { colors, isDevelopment } from "@biblioteksentralen/js-utils";
import { Box, Heading } from "@chakra-ui/layout";
import { Link } from "@chakra-ui/react";
import { usePreviewContext } from "../../preview/previewContext";

function DevKnapper() {
  const previewContext = usePreviewContext();

  if (!isDevelopment()) {
    return null;
  }

  return (
    <Box border={`solid 0.2rem ${colors.lightPeach}`} padding="0.5rem" alignSelf="flex-end">
      <Heading size="sm">Dev-knapper:</Heading>
      <Link as="button" onClick={previewContext.toggle}>
        Skru {previewContext.isOn ? "av" : "på"} forhåndsvisning
      </Link>
    </Box>
  );
}

export default DevKnapper;
