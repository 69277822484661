import { Box, Image, Stack } from "@biblioteksentralen/js-utils";
import { Link } from "@chakra-ui/layout";
import { imageUrlBuilder } from "@libry-content/common";
import { Modify, Municipality } from "@libry-content/types";
import { useCommonData } from "../CommonDataProvider";

type MunicipalityWithCoatOfArms = Modify<Municipality, Required<Pick<Municipality, "coatOfArms">>>;

const hasCoatOfArms = (municipality?: Municipality): municipality is MunicipalityWithCoatOfArms =>
  typeof municipality === "object" && !!municipality?.["coatOfArms"]?.asset;

function CoatOfArms() {
  const { site } = useCommonData();
  if (!hasCoatOfArms(site?.municipality)) return null;

  const additionalMunicipalities = site?.hasAdditionalMunicipalities ? site.additionalMunicipalities ?? [] : [];
  const municipalities = [site?.municipality, ...additionalMunicipalities].filter(hasCoatOfArms);

  return (
    <Stack flexDirection={{ base: "column", sm: "row" }} alignItems="center" gap="1.5rem" marginTop="0">
      {municipalities.map(({ name, coatOfArms, url }) => {
        const LinkOrBox = url ? Link : Box;
        return (
          <LinkOrBox
            key={name}
            href={url}
            variant="plain"
            display="flex"
            alignItems="center"
            gap="0.5rem"
            marginTop="0 !important"
          >
            <Image
              alt=""
              height={{ base: "3rem", sm: "2.5rem" }}
              src={imageUrlBuilder(coatOfArms)?.height(75).url() ?? ""}
            />
            {name && (
              <Box style={{ hyphens: "none" }} width="min-content" lineHeight="1.2" fontSize={{ base: "md", sm: "sm" }}>
                <span translate="no">{name}</span>
              </Box>
            )}
          </LinkOrBox>
        );
      })}
    </Stack>
  );
}

export default CoatOfArms;
