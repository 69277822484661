import { BiSearch, Box, Button, ButtonProps, Text } from "@biblioteksentralen/js-utils";
import { MouseEventHandler } from "react";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { useIsOnSearchPage } from "../../../search/hooks/useIsOnSearchPage";
import { headerLinkDecorationStyle } from "../utils";

type HeaderSearchButtonProps = { onClick: MouseEventHandler; selected: boolean } & ButtonProps;

export const HeaderSearchButton = ({ onClick, selected, ...buttonProps }: HeaderSearchButtonProps) => {
  const { t } = useTranslation();
  const isOnSearchPage = useIsOnSearchPage();

  if (isOnSearchPage) return null;

  return (
    <Button variant="plain" onClick={onClick} css={headerLinkDecorationStyle(selected)} {...buttonProps}>
      <Box>
        <BiSearch />
      </Box>
      <Text marginLeft="0.25rem" fontSize="0.9rem">
        {t("Søk")}
      </Text>
    </Button>
  );
};
