import { BsX, GiHamburgerMenu } from "@biblioteksentralen/js-utils";
import { Box, Collapse, Flex, IconButton, useDisclosure } from "@chakra-ui/react";
import { useEffect } from "react";
import { logEvent } from "../../../utils/analytics/Plausible";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { MobileNavigationLinks } from "./HeaderLinks";
import Logo from "./Logo";

function Header() {
  const { t } = useTranslation();
  const { isOpen, onToggle, onClose } = useDisclosure();

  useEffect(() => {
    isOpen && logEvent("Åpnet mobilmeny");
  }, [isOpen]);

  return (
    <Box>
      <Flex padding={{ base: ".5rem .75rem" }} justifyContent="space-between" alignItems="center">
        <Logo />
        <IconButton
          onClick={onToggle}
          icon={isOpen ? <BsX /> : <GiHamburgerMenu />}
          aria-label={t("Meny")}
          aria-expanded={isOpen}
          display="flex"
          variant="ghost"
          fontSize="1.4rem"
        />
      </Flex>
      <nav>
        <Collapse in={isOpen} animateOpacity>
          <MobileNavigationLinks closeMenu={onClose} />
        </Collapse>
      </nav>
    </Box>
  );
}

export default Header;
