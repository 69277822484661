import { Box, BoxProps, ThemeTypings } from "@biblioteksentralen/js-utils";
import { FunctionComponent, ReactNode } from "react";

type Props = {
  children: ReactNode;
  above?: ThemeTypings["breakpoints"];
  below?: ThemeTypings["breakpoints"];
} & Omit<BoxProps, "display">;

/** Chakras <Hide /> component uses client side javascript to hide components. Causes components to flash on mobile while loading */
export const HideWithCSS: FunctionComponent<Props> = ({ children, above, below, ...chakraProps }) => {
  const display: BoxProps["display"] = {
    ...(!!below ? { base: "none", [below]: "block" } : {}),
    ...(!!above ? { [above]: "none" } : {}),
  };

  return (
    <Box {...chakraProps} display={display}>
      {children}
    </Box>
  );
};
