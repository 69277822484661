import { Site } from "@libry-content/types";
import { TranslateSanityContent } from "../../../utils/hooks/useTranslation";

// Slenger på sort fordi de lengste strengene bør gi treff først. Hvis ikke "folkebibliotek" ligger før "bibliotek" får vi feks rare treff som "Tysnes folke"
const knownEndings = ["folkebibliotek", "bibliotek", "bibliotekene"].sort((a, b) => b.length - a.length);

export const getSiteNameparts = (
  ts: TranslateSanityContent,
  site?: Site
): { boldPart?: string; notBoldPart?: string } => {
  const siteName = ts(site?.name)?.trim();

  const notBoldPart = knownEndings.find((ending) => siteName?.toLowerCase().endsWith(ending));

  if (notBoldPart) {
    const secondaryPartIndex = notBoldPart ? siteName?.toLowerCase()?.indexOf(notBoldPart) : undefined;
    return {
      boldPart: siteName?.slice(0, secondaryPartIndex),
      notBoldPart: siteName?.slice(secondaryPartIndex),
    };
  }

  return { boldPart: siteName, notBoldPart: undefined };
};
