import {
  BiSearch,
  BsX,
  Center,
  CenterProps,
  colors,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
} from "@biblioteksentralen/js-utils";
import { ChangeEventHandler, ComponentProps, FormEventHandler, useEffect, useRef } from "react";
import { useTranslation } from "../../utils/hooks/useTranslation";

export const searchQueryParameterName = "s";

type SubmitButtonProps = ComponentProps<typeof InputRightElement>;

type SearchInputProps = {
  id: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onSubmit: FormEventHandler;
  href?: string;
  label?: string;
  loading?: boolean;
  active?: boolean;
} & CenterProps;

export const SearchInput = ({
  id,
  onSubmit,
  value,
  onChange,
  href,
  label,
  loading,
  active,
  ...centerProps
}: SearchInputProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClear = () => {
    onChange({ target: { value: "" } } as unknown as Parameters<typeof onChange>[0]);
    inputRef.current?.focus();
  };

  // Re-focus input when activated
  useEffect(() => {
    if (!active) return;
    setTimeout(() => inputRef.current?.focus());
  }, [active]);

  const SubmitButton = href
    ? (props: SubmitButtonProps) => <InputRightElement {...props} as="a" />
    : (props: SubmitButtonProps) => <InputRightElement {...props} as="button" />;

  return (
    <Center
      as="form"
      role="search"
      id={id}
      onSubmit={onSubmit}
      width="100%"
      padding="0.5rem 1rem 1.25rem"
      background={colors.accentPeach}
      {...centerProps}
    >
      <InputGroup size="sm" minWidth="8rem" maxWidth="50rem">
        <Input
          name="search"
          ref={inputRef}
          autoFocus
          type="search"
          // TODO: placeholder
          aria-label={t("Søk etter bøker, arrangementer og personer")}
          placeholder={t("Søk etter bøker, arrangementer og personer")}
          onChange={onChange}
          value={value}
          borderRadius="0.25rem"
          background={colors.grey05}
          _hover={{ backgroundColor: colors.grey15 }}
          _focusVisible={{ backgroundColor: colors.grey05 }}
          _placeholder={{ color: colors.grey90 }}
          label={label ?? t("Søk")}
          labelProps={{ fontWeight: "semibold" }}
        />
        {loading && (
          <InputRightElement aria-hidden bottom="0" top="unset" right="2rem">
            <Spinner size="sm" />
          </InputRightElement>
        )}
        {!loading && value && (
          <InputRightElement
            as="button"
            type="button"
            onClick={handleClear}
            aria-label={t("Nullstill søk")}
            padding="0.35rem"
            bottom="0"
            top="unset"
            cursor="pointer"
            right="2rem"
            _hover={{ background: colors.grey10 }}
            transition="0.3s ease background"
          >
            <BsX width="3rem" height="3rem" />
          </InputRightElement>
        )}
        <SubmitButton
          type={href ? undefined : "submit"}
          onClick={href ? undefined : onSubmit}
          href={href}
          aria-label={t("Søk")}
          padding="0.25rem 0.5rem"
          background={colors.black}
          color={colors.white}
          borderRadius="0 0.25rem 0.25rem 0"
          bottom="0"
          top="unset"
          cursor="pointer"
          _hover={{ opacity: "0.75" }}
          transition="0.3s ease opacity"
        >
          <BiSearch />
        </SubmitButton>
      </InputGroup>
    </Center>
  );
};
