import { Box, HiChevronRight, Link } from "@biblioteksentralen/js-utils";
import { Icon, LinkProps, Text } from "@chakra-ui/react";
import { SVGProps } from "react";
import { logEvent } from "../../../utils/analytics/Plausible";
import { useFeatureToggle } from "../../../utils/hooks/featureToggles";
import { useLibrarySystemClient } from "../../../utils/hooks/useLibrarySystemClient";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { headerLinkDecorationStyle } from "../utils";

const MinSideIkon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M14.32 12a5 5 0 0 1 4.797 3.59l.753 2.564A3 3 0 0 1 16.992 22H7.008a3 3 0 0 1-2.878-3.846l.753-2.563A5 5 0 0 1 9.68 12h4.64zM12 2.25a4.25 4.25 0 1 1 0 8.5 4.25 4.25 0 0 1 0-8.5z"
        fill="#000"
      />
    </g>
  </svg>
);

export const MinSideLink = (linkBoxProps: LinkProps) => {
  const librarySystem = useLibrarySystemClient();
  const isBibliofil = librarySystem?.config.type === "bibliofil";
  const nyMinSide = useFeatureToggle("minside");
  const minSideUrl = nyMinSide && isBibliofil ? "/minside" : librarySystem?.getLinkToMyAccount();
  const { t } = useTranslation();

  if (!minSideUrl) return null;

  return (
    <Link
      href={minSideUrl}
      display="flex"
      variant="plain"
      alignItems="center"
      fontWeight="600"
      whiteSpace="nowrap"
      justifyContent={{ base: "space-between", lg: "unset" }}
      onClick={() => logEvent("Gå til min side")}
      css={headerLinkDecorationStyle()}
      {...linkBoxProps}
    >
      <Box as="span" display={{ base: "none", lg: "flex" }} justifyContent="center">
        <Icon as={MinSideIkon} transform={"translateY(-.05rem)"} marginEnd=".2rem" aria-hidden />
      </Box>
      <Text>{t("Min side")}</Text>
      <Box as="span" display={{ base: "flex", lg: "none" }} justifyContent="center">
        <Icon opacity="0.5" w={5} h={5} as={HiChevronRight} aria-hidden />
      </Box>
    </Link>
  );
};
