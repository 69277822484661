import { Collapse, HStack } from "@biblioteksentralen/js-utils";
import { useFeatureToggle } from "../../../utils/hooks/featureToggles";
import { HeaderFooterContainer } from "../../ContentContainer";
import { HeaderSearchInput } from "../../../search/components/HeaderSearchInput";
import { useIsOnSearchPage } from "../../../search/hooks/useIsOnSearchPage";
import { useCommonData } from "../CommonDataProvider";
import LegacyCatalogSearch from "../LegacyCatalogSearch";
import { DesktopNavigationLinks } from "./HeaderLinks";
import { HeaderSearchButton } from "./HeaderSearchButton";
import Logo from "./Logo";
import { MinSideLink } from "./MinSideLink";

type DesktopProps = {
  isSearchingCatalogue: boolean;
  toggleSearchingCatalogue: () => void;
};

function Desktop({ isSearchingCatalogue, toggleSearchingCatalogue }: DesktopProps) {
  const { site } = useCommonData();
  const haveLegacySearch = !!site?.librarySystem;
  const isOnSearchPage = useIsOnSearchPage();
  const searchFeatureToggled = useFeatureToggle("search");

  return (
    <>
      <HeaderFooterContainer as="nav" fontSize="0.9rem" gridGap="2rem" padding="0">
        <HStack justifyContent="space-between" spacing="2rem" padding=".75rem">
          <Logo />
          <DesktopNavigationLinks flexShrink={1} />
          <HStack spacing="0.5rem" flexShrink={1000000}>
            {searchFeatureToggled && (
              <HeaderSearchButton onClick={toggleSearchingCatalogue} selected={isSearchingCatalogue} />
            )}
            {!searchFeatureToggled && haveLegacySearch && (
              <LegacyCatalogSearch librarySystem={site?.librarySystem} id="catalogSearchDesktop" />
            )}
            <MinSideLink />
          </HStack>
        </HStack>
      </HeaderFooterContainer>
      {searchFeatureToggled && !isOnSearchPage && (
        <Collapse in={isSearchingCatalogue} animateOpacity>
          <HeaderSearchInput id="catalogSearchDesktop" active={isSearchingCatalogue} />
        </Collapse>
      )}
    </>
  );
}

export default Desktop;
