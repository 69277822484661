import { Alert, Center, colors } from "@biblioteksentralen/js-utils";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { useCommonData } from "../CommonDataProvider";

export const NotLiveBanner = () => {
  const { t, ts } = useTranslation();
  const site = useCommonData().site;
  if (site?.isLive) return null;

  return (
    <Center backgroundColor={colors.lightPurple} padding="3rem 2rem" fontWeight={600}>
      <Alert status="info" variant="inline">
        {t("Her kommer {navn} sin nye nettside, men den er ikke helt klar enda.", { navn: ts(site?.name) })}
      </Alert>
    </Center>
  );
};
