import { Box, colors, HiChevronRight, HStack, Icon, StackProps } from "@biblioteksentralen/js-utils";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import InternalLink from "../../InternalLink";
import { useCommonData } from "../CommonDataProvider";
import { MinSideLink } from "./MinSideLink";
import { css } from "@emotion/react";
import { headerLinkDecorationStyle } from "../utils";
import { useCurrentCanonicalPath } from "../../../utils/hooks/useCurrentCanonicalPath";
import { HeaderSearchInput } from "../../../search/components/HeaderSearchInput";
import { useFeatureToggle } from "../../../utils/hooks/featureToggles";
import LegacyCatalogSearch from "../LegacyCatalogSearch";

type NavigationLinkConfig = { title: string; path: string };

export const useHeaderNavigationLinks = (): NavigationLinkConfig[] => {
  const { headerFooterData } = useCommonData();
  const { t } = useTranslation();

  if (!headerFooterData) return [];

  const harLeseforslag = !!headerFooterData.bookListCount || !!headerFooterData.recommendationCount;

  return [
    ...(harLeseforslag ? [{ path: "/lister", title: t("Leseforslag") }] : []),
    { path: "/arrangementer", title: t("Arrangementer") },
    ...(headerFooterData.digitalLibraryServiceCount > 0
      ? [{ path: "/digitalt-bibliotek", title: t("Digitalt bibliotek") }]
      : []),
    { path: "/tjenester", title: t("Tjenester og tilbud") },
  ];
};

export function DesktopNavigationLinks(props: StackProps) {
  const currentPath = useCurrentCanonicalPath();
  const navigationLinks = useHeaderNavigationLinks();

  return (
    <HStack
      as="ul"
      listStyleType="none"
      spacing={{ base: "1.5rem", xl: "2rem" }}
      flexWrap="wrap"
      justifyContent="center"
      {...props}
    >
      {navigationLinks.map((link) => (
        <li key={link.path}>
          <InternalLink
            href={link.path}
            variant="plain"
            fontWeight="600"
            whiteSpace="nowrap"
            css={headerLinkDecorationStyle(currentPath.startsWith(link.path))}
          >
            {link.title}
          </InternalLink>
        </li>
      ))}
    </HStack>
  );
}

export function MobileNavigationLinks({ closeMenu, ...props }: { closeMenu: () => void } & StackProps) {
  const navigationLinks = useHeaderNavigationLinks();
  const { site } = useCommonData();
  const haveLegacySearch = !!site?.librarySystem;
  const searchFeatureToggled = useFeatureToggle("search");

  return (
    <Box
      as="ul"
      listStyleType="none"
      borderTop={`${colors.grey15} solid .1rem`}
      css={css`
        > * {
          padding: 0.75rem 0.5rem;
          &:not(:first-child) {
            border-top: solid 0.1rem ${colors.grey10};
          }
        }
      `}
      {...props}
    >
      {searchFeatureToggled && (
        <li>
          <HeaderSearchInput
            id="catalogSearchMobile"
            label=""
            background="none"
            padding="0 0.25rem"
            onBeforeSubmit={closeMenu}
          />
        </li>
      )}
      {!searchFeatureToggled && haveLegacySearch && (
        <li>
          <LegacyCatalogSearch librarySystem={site?.librarySystem} id="catalogSearchMobile" />
        </li>
      )}
      <li>
        <MinSideLink />
      </li>
      {navigationLinks.map((link) => (
        <li key={link.path}>
          <InternalLink
            href={link.path}
            variant="plain"
            fontWeight="600"
            whiteSpace="nowrap"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            css={headerLinkDecorationStyle()}
          >
            {link.title}
            <Icon opacity="0.5" w={5} h={5} as={HiChevronRight} aria-hidden />
          </InternalLink>
        </li>
      ))}
    </Box>
  );
}
