/* eslint i18next/no-literal-string: 0 */
import { colors, Link } from "@biblioteksentralen/js-utils";
import { Box, Heading } from "@chakra-ui/layout";
import React from "react";
import { BsPencil } from "@biblioteksentralen/js-utils";
import { HeaderFooterContainer } from "../../ContentContainer";
import { useEditInSanityContext } from "../../editInSanity/EditInSanityContext";
import { usePreviewContext } from "../../preview/previewContext";
import { useSanityAuth } from "../../sanityAuth/useSanityAuth";
import { logAdminClick } from "../../../utils/analytics/Plausible";

function AdminPanel() {
  const sanityAuth = useSanityAuth();
  const editInSanityContext = useEditInSanityContext();
  const previewContext = usePreviewContext();

  // Skjuler dersom preview er å for å ikke lage rar brukeropplevelse når denne brukes som preview i Sanity
  if (previewContext.isOn) {
    return null;
  }

  if (!sanityAuth.isAuthenticated) {
    return null;
  }

  return (
    <Box backgroundColor={colors.alertYellow} borderBottom={`${colors.lightPeach} solid 0.2rem`}>
      <HeaderFooterContainer
        padding="0.5rem 1rem"
        display="flex"
        flexWrap="wrap"
        gridGap="0.5rem 1rem"
        justifyContent="space-between"
        alignItems="center"
      >
        <Heading as="h4" size="sm" display="flex" gridGap=".5rem" alignItems="center">
          <BsPencil /> Admin
        </Heading>
        <Link href="/cms/" onClick={() => logAdminClick("Gå til editor")}>
          Gå til editor
        </Link>
        <Link
          display="flex"
          gap="0.5rem"
          alignItems="center"
          as="button"
          onClick={() => {
            logAdminClick(`Skru ${editInSanityContext.editMode ? "av" : "på"} redigering`);
            editInSanityContext.toggle();
          }}
        >
          <BsPencil /> Skru {editInSanityContext.editMode ? "av" : "på"} redigering
        </Link>
        <div>{sanityAuth.name}</div>
        <Link
          as="button"
          alignSelf="flex-end"
          onClick={() => {
            logAdminClick("Logg ut");
            sanityAuth.logOut().finally(() => sanityAuth.revalidate());
          }}
        >
          Logg ut
        </Link>
      </HeaderFooterContainer>
    </Box>
  );
}

export default AdminPanel;
