import { LibrarySystem } from "@libry-content/types";

export const generatePrivacyPolicyUrl = (librarySystem?: LibrarySystem): string | undefined => {
  if (librarySystem?.type === "bibliofil") {
    return `https://${librarySystem.database}.bib.no/bs/personvernerklaring.html`;
  }
  if (librarySystem?.type === "mikromarc") {
    return `https://${librarySystem.database}.mikromarc.no/Mikromarc3/privacypolicy.aspx?Unit=${librarySystem.mikromarcUnit}&db=${librarySystem.database}`;
  }
};
