import { Box, useBoolean } from "@biblioteksentralen/js-utils";
import { useRef } from "react";
import { useClickAway } from "react-use";
import { PageHeader } from "../PageHeader";
import AdminPanel from "./AdminPanel";
import Desktop from "./Desktop";
import Mobile from "./Mobile";
import { NotLiveBanner } from "./NotLiveBanner";

function Header() {
  const ref = useRef(null);
  const [isSearchingCatalogue, { toggle: toggleSearchingCatalogue, off: stopSearchingCatalogue }] = useBoolean(false);

  useClickAway(ref, stopSearchingCatalogue);

  return (
    <PageHeader ref={ref}>
      <NotLiveBanner />
      <AdminPanel />
      <Box display={{ base: "none", lg: "block" }}>
        <Desktop isSearchingCatalogue={isSearchingCatalogue} toggleSearchingCatalogue={toggleSearchingCatalogue} />
      </Box>
      <Box display={{ base: "block", lg: "none" }}>
        <Mobile />
      </Box>
    </PageHeader>
  );
}

export default Header;
