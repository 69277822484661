import { css } from "@emotion/react";

export const headerLinkDecorationStyle = (selected = false) => css`
  text-underline-offset: 5px;
  text-decoration-thickness: 2px;
  text-decoration-line: ${selected ? "underline" : "none"};

  &:hover {
    text-decoration-line: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;
  }
`;
