import { useRouter } from "next/router";
import { ChangeEventHandler, ComponentProps, FormEventHandler, useState } from "react";
import { SearchInput, searchQueryParameterName } from "./SearchInput";

type HeaderSearchInputProps = { onBeforeSubmit?: () => void; active?: boolean } & Omit<
  ComponentProps<typeof SearchInput>,
  "onSubmit" | "value" | "onChange"
>;

export const HeaderSearchInput = ({ onBeforeSubmit, active, ...props }: HeaderSearchInputProps) => {
  const [inputValue, setInputValue] = useState("");
  const router = useRouter();

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => setInputValue(event.target.value);

  const href = `/sok?${searchQueryParameterName}=${encodeURIComponent(inputValue)}`;

  const onSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    onBeforeSubmit?.();
    router.push(href);
  };

  return (
    <SearchInput onSubmit={onSubmit} value={inputValue} onChange={onChange} href={href} active={active} {...props} />
  );
};
