import { useRouter } from "next/router";

/**
 * The pathname looks different on server and client due to rewrites;
 * we choose the latter as canonical
 */
export const useCurrentCanonicalPath = () => {
  const { pathname } = useRouter();
  return pathname.replace(/\/sites\/\[site\]/, "");
};
