import { Box, ConditionalWrapper, Flex, HStack, Image, Text, VisuallyHidden } from "@biblioteksentralen/js-utils";
import { imageUrlBuilder } from "@libry-content/common";
import NextLink from "next/link";
import { useCurrentCanonicalPath } from "../../../utils/hooks/useCurrentCanonicalPath";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { HideWithCSS } from "../../HideWithCSS";
import { useCommonData } from "../CommonDataProvider";
import { headerLinkDecorationStyle } from "../utils";
import { getSiteNameparts } from "./getSiteNameParts";

function Logo() {
  const { t } = useTranslation();
  const isOnFrontPage = useCurrentCanonicalPath() === "";

  return (
    <ConditionalWrapper wrapper={(props) => <h1 {...props} />} condition={isOnFrontPage}>
      <NextLink href="/">
        {!isOnFrontPage && <VisuallyHidden>{t("Forsiden")}</VisuallyHidden>}
        <Flex role="group" translate="no" flexShrink={0}>
          <HideWithCSS below="sm">
            <DesktopLogo />
          </HideWithCSS>
          <HideWithCSS above="sm">
            <MobileLogo />
          </HideWithCSS>
        </Flex>
      </NextLink>
    </ConditionalWrapper>
  );
}

const DesktopLogo = () => {
  const { site } = useCommonData();
  const showLogoText = !site?.logoContainsName || !site?.logo?.asset;

  return (
    <HStack>
      {site?.logo?.asset && (
        <Box display={{ base: "none", sm: "block" }}>
          <Image height="2rem" src={imageUrlBuilder(site?.logo)?.height(75).url() ?? ""} alt="" />
        </Box>
      )}
      <ConditionalWrapper wrapper={VisuallyHidden} condition={!showLogoText}>
        <Name />
      </ConditionalWrapper>
    </HStack>
  );
};

const MobileLogo = () => <Name />;

const Name = () => {
  const { ts } = useTranslation();
  const { site } = useCommonData();

  const { boldPart, notBoldPart } = getSiteNameparts(ts, site);

  return (
    <Text
      textTransform="uppercase"
      _groupHover={{ textDecorationLine: "underline" }}
      lineHeight="1"
      transform="translateY(.1em)"
      fontSize={{ base: "1rem", lg: "0.9rem" }}
      css={headerLinkDecorationStyle()}
      whiteSpace="nowrap"
      style={{ hyphens: "none" }}
    >
      <Box as="span" fontWeight={600}>
        {boldPart}
      </Box>
      {notBoldPart && <Box as="span">{notBoldPart}</Box>}
    </Text>
  );
};

export default Logo;
