import { Box } from "@biblioteksentralen/js-utils";
import { ReactNode, useRef } from "react";
import { useTranslation } from "../../utils/hooks/useTranslation";
import SkipNavButton from "../SkipNavButton";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import { LayoutGrid } from "./LayoutGrid";
import { CommonDataProvider } from "./CommonDataProvider";
import { CommonData } from "../../utils/fetchWithCommonDataAndCurrentSiteFilter";

interface Props {
  commonData: CommonData;
  children: ReactNode;
}

function CommonPageWrapper(props: Props) {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <CommonDataProvider commonData={props.commonData}>
      <LayoutGrid>
        <SkipNavButton title={t("Hopp til hovedinnhold")} focusOnClick={() => ref.current?.focus()} />
        <Header />
        <Box id="main" as="main" maxWidth="100vw" tabIndex={-1} ref={ref}>
          {props.children}
        </Box>
        <Footer />
      </LayoutGrid>
    </CommonDataProvider>
  );
}

export default CommonPageWrapper;
