import { filters } from "@libry-content/common";
import { Site } from "@libry-content/types";
import { groq } from "next-sanity";
import { useEffect, useState } from "react";
import {
  fetchWithCommonDataAndCurrentSiteFilter,
  ResolvedSite,
  WithPreviewConfigAndCommonSiteData,
} from "../../utils/fetchWithCommonDataAndCurrentSiteFilter";
import { sanityClient } from "../../utils/sanity/client";
import { getHostName } from "../../utils/useSubdomainAliasRedirect";

type SiteSummary = Pick<Site, "_id" | "name" | "subdomain" | "customDomain" | "defaultLanguage" | "municipality">;

const siteSummaryQuery = groq`*[ _type == "site" && ${filters.isNotDraft} && isLive && defined(municipality.coatOfArms.asset._ref)]{
  _id, name, subdomain, customDomain, defaultLanguage, municipality
}`;

export type ErrorPageProps = WithPreviewConfigAndCommonSiteData<{ data: { site: ResolvedSite } }>;

export const useErrorPageData = () => {
  const hostname = getHostName();
  const [site, setSite] = useState<ResolvedSite | undefined>(undefined);
  const [allSites, setAllSites] = useState<SiteSummary[] | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (typeof hostname === "undefined") return;
    setLoading(true);

    fetchWithCommonDataAndCurrentSiteFilter<ErrorPageProps>(groq`{}`, { siteDomain: hostname })
      .then(async ({ data }) => {
        setLoading(false);

        if (data?.commonData?.site) {
          setSite(data.commonData.site);
          return;
        }

        setAllSites(await sanityClient.fetch<SiteSummary[]>(siteSummaryQuery));
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [hostname]);

  return { site, allSites, loading };
};
